
import { Col, Container, Row } from "react-bootstrap";
import { BgImage } from "../../components";
import { socialNetworks } from "../../constants";
export default function Footer() {
  return (
    <footer className="position-relative bottom-0 py-4">
      <BgImage type={2} fullSize objectFit="cover" />
      <Container>
        <Row>
          <Col xs="12" lg="8">
            <Row className="text-center align-items-stretch">
              <Col xs="12" md="6" className="py-2">
                <h4 className="text-light mb-3">خدمات آکادمی مارول</h4>

                <div className="d-flex flex-column flex-center row-gap-4">
                  <a href="https://careers.academymarvel.com/">فرصت های شغلی</a>
                </div>
              </Col>
              <Col
                xs="12"
                md="6"
                className=" py-2"
              >
                <h4 className="text-light mb-3">شبکه‌های اجتماعی</h4>
                <div className="d-flex flex-center gap-5">
                  {socialNetworks.map((item, index) => (
                    <a
                      target="_blank"
                      key={index}
                      href={item.link}
                      className={`transition fs-20 bi bi-${item.icon}`}
                    >
                      {""}
                    </a>
                  ))}
                </div>
              </Col>
              {/* <Col xs="12" md="3" className="py-2">
                <div className="d-flex flex-column flex-center row-gap-4">
                  <img width="30" src={envelopeIcon} alt="envelope" />
                  <p className="text-light">
                    google@gmail.com
                    <br />
                    google@gmail.com
                  </p>
                </div>
              </Col> */}
            </Row>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

