const landing = [
  {
    label: "خانه",
    path: "/",
  },
  // {
  //   label: "بلاگ",
  //   path: "/blog/",
  // },
  {
    label: "دوره ها",
    path: "/tutorials",
  },
  {
    label: "کتابخوانی",
    path: "/book-reading",
  },
  {
    label: "دوره های رایگان",
    path: "free-tutorials",

  },
  {
    label: "فروشگاه کتاب",
    path: "/products",
  },
  {
    label: "ترید خصوصی",
    path: "/private-trade",
    icon: "clock-history",
  },
  {
    label: "کانال نقطه صفر",
    path: "/zero-point",
    icon: "clock-history",
  },
];

const user = [
  // {
  //   label: "خانه",
  //   path: "/",
  //   icon: "house-fill",
  // },

  // {
  //   label: "دوره ها",
  //   path: "tutorials",
  //   icon: "mortarboard",
  // },
  // {
  //   label: "بلاگ",
  //   path: "/blog/",
  //   icon: "file-richtext-fill",
  // },
  // {
  //   label: "دوره های رایگان",
  //   path: "free-tutorials",
  //   icon: "mortarboard",
  // },
  // {
  //   label: "فروشگاه",
  //   path: "products",
  //   icon: "cart3",
  // },
  
  // {
  //   label: "لیدربورد مارول",
  //   path: "leader-board",
  //   icon: "bar-chart-line-fill",
  // },
  // {
  //   label: "کلاس‌ها",
  //   path: "classes",
  //   icon: "mortarboard-fill",
  // },
  // {
  //   label: "آزمون‌های",
  //   path: "exams",
  //   icon: "file-earmark-fill",
  // },
  {
    label: "پیشخوان",
    path: "dashboard",
    icon: "bag-fill",
  },
  {
    label: "سفارشات من",
    path: "/my-order",
    icon: "bag-fill",
  },
  {
    label: "لایسنس های من",
    path: "/license",
    icon: "key",
  },
  {
    label: "اقساط من",
    path: "/installments",
    icon: "journals",
  },
  // {
  //   label: "مشاوره خصوصی",
  //   path: "/buy-consultation",
  //   icon: "clock-history",
  // },
  {
    label: "اطلاعات کاربری",
    path: "/profile",
    icon: "person",
  },
  {
    label: "آدرس های من",
    path: "/my-address",
    icon: "person",
  },
  {
    label: "تغییر رمزعبور",
    path: "/change-password",
    icon: "person",
  },
  // {
  //   label: "تقویم کلاس ها",
  //   path: "/class-calendar",
  //   icon: "person",
  // },
];


const admin = [
  {
    title: "تنظیمات",
    children: [
      // {
      //   label: "بلاگ",
      //   path: "blogs",
      //   icon: "columns",
      // },
      {
        label: "دسته‌بندی‌ها",
        path: "categories",
        icon: "tags",
      },
      // {
      //   label: "کدهای تخفیف",
      //   path: "vouchers",
      //   icon: "percent",
      // },
      // {
      //   label: "اعلان‌ها",
      //   path: "notifications",
      //   icon: "bell",
      // },
      {
        label: "آدرس کیف پول",
        path: "wallet",
        icon: "wallet2",
      },
      {
        label: "ویدیوهای رایگان",
        path: "free-tutorials",
        icon: "film",
      },
      {
        label: "مدیریت کاربران",
        path: "/admin/users",
        icon: "people",
      },
    ],
  },
  {
    title: "کلاس",
    children: [
      {
        label: "مشاوره",
        path: "/admin/advice",
        icon: "clock-history",
      },
      {
        label: "ایجاد گروه",
        path: "/admin/groups",
        icon: "table",
      },
      {
        label: "ارسال پیامک",
        path: "/admin/send-sms",
        icon: "chat-left-dots",
      },
      {
        label: "ارسال پیامک دوره",
        path: "/admin/groups-message",
        icon: "table",
      },
      {
        label: "چارت ستاره شناسی",
        path: "/admin/astrologyCharts",
        icon: "stars",
      },

    ],
  },
  {
    title: "دوره",
    children: [
      {
        label: "دوره‌های آموزشی",
        path: "/admin/courses",
        icon: "card-list",
      },
      {
        label: "اسلایدر",
        path: "sliders",
        icon: "tags",
      },
      {
        label: "رسانه ها",
        path: "medias",
        icon: "tags",
      },
      {
        label: "لیست خریدهای زرین پال",
        path: "purchases-course",
        icon: "cash",
      },
      {
        label: "لیست خریدهای تتری",
        path: "/admin/tethers-course",
        icon: "currency-bitcoin",
      },
      {
        label: "لیست کارت به کارت",
        path: "/admin/card-purchases-course",
        icon: "credit-card",
      },
      {
        label: "مدیریت مدرسان",
        path: "/admin/teachers",
        icon: "people",
      },
      
      // {
      //   label: "اقساط",
      //   path: "/admin/installment",
      //   icon: "table",
      // },
      // {
      //   label: "تقویم",
      //   path: "/admin/calendars",
      //   icon: "table",
      // },
    ],
  },
  {
    title: "کتاب",
    children: [
      // {
      //   label: "مشخصات ارسال کننده",
      //   path: "sender-info",
      //   icon: "box-seam",
      // },
      {
        label: "هزینه ارسال",
        path: "shipping-info",
        icon: "truck",
      },
      {
        label: "محصولات فروشگاه",
        path: "products",
        icon: "cart3",
      },
      {
        label: "لیست خریدهای زرین پال",
        path: "purchases",
        icon: "cash",
      },
      {
        label: "لیست خریدهای تتری",
        path: "/admin/tethers",
        icon: "currency-bitcoin",
      },
      {
        label: "لیست کارت به کارت",
        path: "/admin/card-purchases",
        icon: "chat-left-dots",
      },
    ],
  },
  // {
  //   label: "جلسات",
  //   path: "/admin/sessions",
  //   icon: "file-earmark-play",
  // },
  // {
  //   label: "آزمون‌ها",
  //   path: "/admin/exams",
  //   icon: "list-task",
  // },
  // {
  //   label: "بازه‌های ثبت‌نام",
  //   path: "intervals",
  //   icon: "calendar2-range",
  // },

  {
    label: "داشبورد کاربران",
    path: "/profile",
    icon: "person",
  },
  {
    label: "صفحه اصلی",
    path: "/",
    icon: "house",
  },
];

const accountant = [
  {
    label: "داشبورد",
    path: "dashboard",
    icon: "speedometer2",
  },
  {
    label: " خریدهای آکادمی زرین پال",
    path: "/accountant/zarinpal",
    icon: "cash",
  },
  {
    label: "خریدهای  آکادمی تتری",
    path: "/accountant/tether",
    icon: "currency-bitcoin",
  },
  {
    label: "کارت به کارت های آکادمی",
    path: "/accountant/card-purchases",
    icon: "credit-card",
  },
  {
    label: "خرید های VPS زرین پال",
    path: "/accountant/zarinpal-vps",
    icon: "cash",
  },
  {
    label: "خرید های VPS  تتری",
    path: "/accountant/tether-vps",
    icon: "currency-bitcoin",
  },
  {
    label: "خرید های مارول زرین پال",
    path: "/accountant/zarinpal-pardakht",
    icon: "cash",
  },
  {
    label: "خرید های مارول تتری",
    path: "/accountant/tether-pardakht",
    icon: "currency-bitcoin",
  },
  {
    label: "داشبورد کاربری",
    path: "/profile",
    icon: "person",
  },
  {
    label: "صفحه اصلی",
    path: "/",
    icon: "house",
  },
]
const navItems = { landing, user, admin, accountant };
export default navItems;
