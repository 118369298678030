import React, { useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { Button, Modal } from '../../components'
import { navItems, rolesPath } from "../../constants";
// import logoText from "../../assets/logos/logo text2.png";
// import logo from "../../assets/logos/logo.svg";
import logo from '../../assets/logos/Marvel academy.svg'
import Menu from "./Menu";
import Newlogin from "../../pages/LoginLayout/NewLogin";
import { useShowFullName } from "../../hooks";
import CartLink from "./CartLink";
export default function Header() {
  const showFullName = useShowFullName();
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false)
  const {pathname} = useLocation();
  const role = useSelector((s) => s.role);
  const isLogged = useSelector((s) => s.isLogged)
  const signInPath = rolesPath[role] ?? "/";
  return (
    <div className={`${pathname == "/" ? "" : "bg-dark-black"}`}>
      <header className={`container position-relative w-100 d-flex align-items-center justify-content-between  p-3 col-gap-3`}>
        <button
        style={{zIndex : "3"}}
          onClick={() => setShow(true)}
          className="bi bi-list fs-1 d-flex fle-center d-xl-none text-white"
        />
        <img
          width="65"
          src={logo}
          alt="logo"
          className=""
        />
        {/* <div className="d-flex d-xl-none">
          {isLogged && <CartLink /> }
          {!isLogged &&
            <Button onClick={() => setShowModal(true)} className="btn btn-primary Button fs-12 py-2 fw-500 d-block d-lg-none mx-2">
              ورود
            </Button>
          }
        </div> */}
        
        <nav className="flex-1 d-none d-xl-block">
          <ul className="d-flex align-items-center col-gap-3">
            {
              navItems.landing.map((item, index) => (
                <li key={index}>
                  <NavLink
                    to={item.path}
                    className={({ isActive }) =>
                      `text-${isActive ? "white" : "gray-white"}`
                    }
                  >
                    {item.label}
                  </NavLink>
                </li>
              ))}
            {/* {isLogged &&
              navItems.user.map((item, index) => (
                <li key={index}>
                  <NavLink
                    to={item.path}
                    className={({ isActive }) =>
                      `text-${isActive ? "white" : "gray-white"}`
                    }
                  >
                    {item.label}
                  </NavLink>
                </li>
              ))} */}
              <li><a className="fs-16 fw-500 text-gray-white" href="https://academymarvel.com/blog/">بلاگ</a></li>
              <li><a className="fs-16 fw-500 text-gray-white" href="https://careers.academymarvel.com/">فرصت های شغلی</a></li>

          </ul>
        </nav>
        <div className="user-control d-flex flex-center col-gap-3">
          <div className="position-relative d-none d-xl-block">

            {/* <Link to="/cart" className="bi bi-cart3 fs-3 text-primary" /> */}
            <CartLink />
          </div>
          {
            !isLogged ?
              <Button onClick={() => setShowModal(true)} className="btn btn-quartz Button fs-12 py-2 fw-500">
                ورود
              </Button>
              :
              <NavLink to={signInPath} className="btn btn-primary Button fs-14 w-100 py-2 fw-500">
                {showFullName()}
              </NavLink>
          }
        </div>
      </header>
      <Menu signInPath={signInPath} show={show} onHide={setShow} />
      <Newlogin show={showModal} onHide={setShowModal} />

    </div>
  );
}
