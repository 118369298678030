import React from 'react'
const TimeToGet = ({data = {time : 5}}) => {
  return (
    <div className="wrapper mb-4 d-flex align-items-center justify-content-between">
        <p className='text-white'><span className="fw-700 text-white fs-16"><i className="bi bi-clock"></i>  زمان ارسال : </span><span className='fs-14 fw-400'> {data?.time} روز کاری دیگر </span></p>
        <i className="bi bi-truck text-primary fs-25"></i>
    </div>
  )
}

export default TimeToGet